import { AddWorkingHourDtoTypeEnum, UpdateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import { Form } from '@components/forms'
import { Select, TextInput } from '@components/inputs'
import { Button, ComponentWrapper } from '@components/ui'
import { useWorkingHoursFunctions } from '@hooks/api/working-hours/useWorkingHoursFunctions'
import { EmployeeDepartment } from '@interfaces/employee'
import { useMe } from '@stores/me.store'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EmployeeClients, WorkingHoursTypeEnum } from '../../../../types'
import { NotificationModal } from '../../Components/NotificationModal'

interface IAddWorkingHoursProps {
  date: string
  notEnteredHours: number
  employeeDepartments: EmployeeDepartment[]
  employeeClients: EmployeeClients[]
  refetchHours: () => Promise<void>
  onSubmit?: () => void
}

export const AddWorkingHours = ({
  date,
  notEnteredHours,
  employeeClients,
  employeeDepartments,
  refetchHours,
  onSubmit,
}: IAddWorkingHoursProps) => {
  const { me } = useMe()
  const [isTooManyHoursModalOpen, setIsTooManyHoursModalOpen] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const hasFilledAllHours = notEnteredHours === 0
  const isInOvertime = hasFilledAllHours && me?.companySettings?.hasOvertime

  const methods = useForm<{ hours: number; type: AddWorkingHourDtoTypeEnum; client: number; department: number }>({
    defaultValues: {
      hours: 0,
      type: isInOvertime ? AddWorkingHourDtoTypeEnum.OVERTIME : AddWorkingHourDtoTypeEnum.NORMAL,
      department: employeeDepartments.length > 0 ? employeeDepartments?.[0].id : undefined,
    },
  })
  const { createWorkingHours } = useWorkingHoursFunctions()
  const [year, month] = (date || '').split('-')
  const isStudent = me?.employee?.hoursType === UpdateEmployeeDtoHoursTypeEnum.STUDENT
  const workingHoursTypes = useMemo(
    () =>
      isInOvertime
        ? [{ title: t('working_hours.type.overtime'), value: AddWorkingHourDtoTypeEnum.OVERTIME }]
        : [
            { title: t('working_hours.type.normal'), value: AddWorkingHourDtoTypeEnum.NORMAL },
            {
              title: t('working_hours.type.wfh'),
              value: AddWorkingHourDtoTypeEnum.WFH,
              hidden: !me?.companySettings?.hasWFH,
            },
            {
              title: t('working_hours.type.overtime'),
              value: AddWorkingHourDtoTypeEnum.OVERTIME,
              hidden: !me?.companySettings?.hasOvertime || isStudent,
            },
            { title: t('working_hours.type.maternity'), value: AddWorkingHourDtoTypeEnum.MATERNITY, hidden: isStudent },
            { title: t('working_hours.type.sick'), value: AddWorkingHourDtoTypeEnum.SICK, hidden: isStudent },
            { title: t('working_hours.type.vacation'), value: AddWorkingHourDtoTypeEnum.VACATION, hidden: isStudent },
            { title: t('working_hours.type.rest'), value: AddWorkingHourDtoTypeEnum.REST },
          ],
    [isInOvertime, me?.companySettings?.hasOvertime, me?.companySettings?.hasWFH, isStudent, t],
  )

  const clients = useMemo(
    () => (employeeClients || []).map(empClient => ({ title: empClient.name, value: empClient.id })),
    [employeeClients],
  )

  const departments = useMemo(
    () => (employeeDepartments || []).map(empClient => ({ title: empClient.name, value: empClient.id })),
    [employeeDepartments],
  )

  const [workingHoursType, hours, client, department] = methods.watch(['type', 'hours', 'client', 'department'])

  useEffect(() => {
    if (isInOvertime && workingHoursType !== AddWorkingHourDtoTypeEnum.OVERTIME) {
      methods.setValue('type', AddWorkingHourDtoTypeEnum.OVERTIME)
    }
  }, [isInOvertime, methods, workingHoursType])

  useEffect(() => {
    if (!department && departments.length > 0) {
      methods.setValue('department', departments[0].value)
    }
  }, [department, departments, methods])

  const isWorkingHourSelected = Object.values(WorkingHoursTypeEnum).includes(workingHoursType)
  const isValid =
    hours > 0 && (isWorkingHourSelected && workingHoursType !== AddWorkingHourDtoTypeEnum.REST ? !!client : true)

  const handleSubmitHours = methods.handleSubmit(async v => {
    const tempCurrentHours = isWorkingHourSelected ? Number(v.hours) : Number(v.hours) * 8

    if (tempCurrentHours > notEnteredHours && !isInOvertime && !isStudent) {
      setIsTooManyHoursModalOpen(true)
      return
    }
    await createWorkingHours.mutateAsync(
      {
        hours: tempCurrentHours,
        type: v.type,
        year: Number(year),
        month: Number(month),
        departmentId: v.department,
        clientId: v.client,
      },
      { onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] }) },
    )
    await refetchHours()
    if (onSubmit) {
      onSubmit()
    }
    methods.reset()
  })

  return (
    <ComponentWrapper>
      <Form methods={methods} onSubmit={handleSubmitHours}>
        <div className={'flex items-center justify-between gap-10'}>
          <div className={'flex gap-10'}>
            <Select
              id='type'
              placeholder={t('working_hours.select') as string}
              name='type'
              label={t('working_hours.entry_type')}
              options={workingHoursTypes}
              hasSearch
            />
            {departments.length > 1 && isWorkingHourSelected && (
              <Select
                id='department'
                name='department'
                hasSearch
                label={t('working_hours.department')}
                options={departments}
                placeholder={t('working_hours.select') as string}
              />
            )}
            {isWorkingHourSelected && workingHoursType !== AddWorkingHourDtoTypeEnum.REST && (
              <Select
                id='client'
                name='client'
                hasSearch
                label={t('working_hours.client')}
                options={clients}
                placeholder={t('working_hours.select') as string}
              />
            )}
            <TextInput
              label={
                isWorkingHourSelected
                  ? t('working_hours.number_of_working_hours')
                  : t('working_hours.number_of_working_days')
              }
              type={'number'}
              id='hours'
              name='hours'
              min={0}
              containerStyle={'max-w-[150px]'}
            />
          </div>
          <Button disabled={!isValid || (!me?.companySettings?.hasOvertime && isInOvertime)}>
            {t('working_hours.save')}
          </Button>
        </div>
      </Form>
      <NotificationModal
        key={'too_many_hours'}
        isOpen={isTooManyHoursModalOpen}
        onClose={() => setIsTooManyHoursModalOpen(false)}
        title={'Broj unesenih sati veći je od maksimalnog broja sati u mjesecu'}
        subtitle={'Samo prekovremeni sati mogu biti uneseni iznad maksimalnog broja sati za ovaj mjesec.'}
      />
    </ComponentWrapper>
  )
}
