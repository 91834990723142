import {
  UpdateEmployeeDtoHoursTypeEnum,
  WorkingHoursMonthDtoTypeEnum,
  WorkingHoursStatusDtoStatusEnum,
} from '@api/generated-api'
import Assets from '@assets/assets'
import { Button, ComponentWrapper, PageContent } from '@components/ui'
import { useGetWorkingHoursStatusByDate } from '@hooks/api/working-hours/useGetWorkingHoursStatusByDate'
import { useMonthAvailableWorkingHours } from '@hooks/api/working-hours/useMonthAvailableWorkingHours'
import { useWorkingHoursEmployeeClients } from '@hooks/api/working-hours/useWorkingHoursEmployeeClients'
import { useWorkingHoursEmployeeDepartments } from '@hooks/api/working-hours/useWorkingHoursEmployeeDepartments'
import { useWorkingHoursFunctions } from '@hooks/api/working-hours/useWorkingHoursFunctions'
import { useMe } from '@stores/me.store'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'twin.macro'
import { Chip } from '../../../../components/UI/Chip/Chip'
import { NotificationModal } from '../../Components/NotificationModal'
import { AddWorkingHours } from './AddWorkingHours'
import { NonWorkingHoursTable } from './NonWorkingHoursTable'
import { WorkingHoursLine } from './WorkingHoursLine'
import { WorkingHoursTable } from './WorkingHoursTable'

export const colorsByType = {
  NORMAL: '#DCF6DD',
  NON_WORKING: '#E4DCF6',
  OVERTIME: '#FDC69E',
  NO_ENTRY: '#D9D9D9',
}

export enum EditableWorkingHoursColumnsEnum {
  CLIENT_ID = 'clientId',
  HOURS = 'hours',
  DEPARTMENT_ID = 'departmentId',
}

export interface IEditColumnProps {
  change: number
  whId: number
  column: EditableWorkingHoursColumnsEnum
}

export const WorkingHoursMonthContent = () => {
  const { id = '' } = useParams()
  const { me } = useMe()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isSendToManagerModalOpen, setIsSendToManagerModalOpen] = useState(false)
  const [isAddDataOpen, setIsAddDataOpen] = useState(false)
  const queryClient = useQueryClient()
  const [isSuccesfullySentModalOpen, setIsSuccesfullySentModalOpen] = useState(false)
  const [isNotEnoughHoursModalOpen, setIsNotEnoughHoursModalOpen] = useState(false)
  const { data: statusData, refetch } = useGetWorkingHoursStatusByDate({ date: id, employeeId: me?.employeeId })
  const [year, month] = (id || '').split('-')
  const { data: availableHours } = useMonthAvailableWorkingHours({ month: Number(month), year: Number(year) })
  const { data: employeeClients = [] } = useWorkingHoursEmployeeClients()
  const { data: employeeDepartments = [] } = useWorkingHoursEmployeeDepartments()

  const hoursByDepartment = (statusData?.workingHours || []).map(wh => ({
    hours: wh.workingHours.reduce((previousValue, currentValue) => previousValue + currentValue.hours, 0),
    department: wh.department,
  }))

  const { updateWorkingHours, deleteWorkingHours, sendToManager } = useWorkingHoursFunctions()

  const allData = useMemo(
    () =>
      [
        ...(statusData?.workingHours || []).map(wh => wh?.workingHours || []),
        ...(statusData?.nonWorkingHours || []),
      ].flat(),
    [statusData],
  )

  const overtimeHours = allData.reduce(
    (previousValue, currentValue) =>
      previousValue + (currentValue.type === WorkingHoursMonthDtoTypeEnum.OVERTIME ? currentValue.hours : 0),
    0,
  )

  const notes = (statusData?.workingHours || [])
    .map(wh => ({ note: wh.note, department: wh.department }))
    .filter(note => !!note?.note)

  const totalHours = allData.reduce(
    (previousValue, currentValue) => previousValue + Math.max(Number(currentValue.hours), 0),
    0,
  )

  const notEnteredHours =
    me?.employee?.hoursType === UpdateEmployeeDtoHoursTypeEnum.STUDENT
      ? 0
      : Math.max((availableHours || 0) - totalHours + overtimeHours, 0)

  const isEditable = useMemo(
    () =>
      (statusData?.workingHours || [])
        .map(
          wh =>
            wh.status !== WorkingHoursStatusDtoStatusEnum.APPROVED &&
            wh.status !== WorkingHoursStatusDtoStatusEnum.SENT,
        )
        .some(item => !!item),
    [statusData?.workingHours],
  )

  const isSomeRejected = useMemo(
    () =>
      (statusData?.workingHours || [])
        .map(wh => wh.status === WorkingHoursStatusDtoStatusEnum.REJECTED)
        .some(item => !!item),
    [statusData?.workingHours],
  )

  const isStudent = me?.employee?.hoursType === UpdateEmployeeDtoHoursTypeEnum.STUDENT

  const onEditColumn = useCallback(
    async ({ change, whId, column }: IEditColumnProps) => {
      try {
        await updateWorkingHours.mutateAsync(
          {
            data: { [column]: Number(change) },
            id: whId,
          },
          {
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] }),
          },
        )
        await refetch()
      } catch (e) {
        console.log('Error updating WH')
      }
    },
    [queryClient, refetch, updateWorkingHours],
  )

  const handleDeleteRow = async (id: number) => {
    if (id) {
      await deleteWorkingHours.mutateAsync(id, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['working-hours', 'active'] }),
      })
      await refetch()
    }
  }

  const handleSendWorkingHours = useCallback(async () => {
    setIsLoading(true)
    await sendToManager.mutateAsync({ month: Number(month), year: Number(year) })
    await refetch()
    setIsLoading(false)
    setIsSendToManagerModalOpen(false)
    setIsSuccesfullySentModalOpen(true)
  }, [month, refetch, sendToManager, year])

  if (!availableHours) {
    return null
  }

  return (
    <PageContent className={'bg-table-accent-grey flex flex-1 flex-col justify-between'}>
      <div>
        {isEditable && id && !isSomeRejected && (
          <AddWorkingHours
            date={id}
            employeeClients={employeeClients}
            employeeDepartments={employeeDepartments}
            notEnteredHours={notEnteredHours}
            refetchHours={refetch}
          />
        )}
        <div className='-mt-4 mb-4 text-[#808080]'>Uneseni sati bit će automatski spremljeni.</div>
        {(notes || []).map(note => (
          <ComponentWrapper className={'space-y-4'} key={note?.note}>
            <div className={'flex space-x-2'}>
              <img src={Assets.NoteIcon} alt={'note-icon'} />
              <div className={'font-medium'}>
                {t('working_hours.hours_for_department_not_approved', { name: note?.department?.name })}
              </div>
            </div>
            <div className={'max-w-5xl'}>{note?.note}</div>
          </ComponentWrapper>
        ))}
        {totalHours >= 0 && (
          <ComponentWrapper>
            <div className={'mb-4'}>
              <div className={'mb-2 flex items-center justify-between'}>
                <div className={'text-[24px] text-gray-600'}>{t('working_hours.entry')}</div>
                {isSomeRejected && !isAddDataOpen && (
                  <Button buttonType={'secondary'} onClick={() => setIsAddDataOpen(true)}>
                    Novi unos
                  </Button>
                )}
              </div>
              {isAddDataOpen && (
                <AddWorkingHours
                  date={id}
                  employeeClients={employeeClients}
                  employeeDepartments={employeeDepartments}
                  notEnteredHours={notEnteredHours}
                  refetchHours={refetch}
                  onSubmit={() => setIsAddDataOpen(false)}
                />
              )}
              <WorkingHoursLine
                availableHours={availableHours}
                totalHours={totalHours}
                isStudent={isStudent}
                notEnteredHours={notEnteredHours}
                allData={allData}
                hoursByDepartment={hoursByDepartment}
              />
            </div>
            {(statusData?.workingHours || [])
              .filter(wh => (wh?.workingHours || []).length !== 0)
              .map(wh => (
                <div key={wh.id} className={'mt-4'}>
                  <div className={'flex items-center gap-4'}>
                    <span className="font-['IBM Plex Sans'] text-lg font-medium leading-relaxed text-neutral-700">
                      Odjel {wh.department.name}
                    </span>
                    <Chip
                      state={
                        wh.status === WorkingHoursStatusDtoStatusEnum.NOTSENT && notEnteredHours === 0
                          ? 'POPULATED'
                          : wh.status
                      }
                    />
                  </div>
                  <WorkingHoursTable
                    notEnteredHours={notEnteredHours}
                    data={wh?.workingHours || []}
                    availableHours={availableHours}
                    onDeleteRow={handleDeleteRow}
                    isStudent={isStudent}
                    isEditable={isEditable}
                    onEditColumn={onEditColumn}
                  />
                </div>
              ))}
            {(statusData?.nonWorkingHours || []).length > 0 && (
              <NonWorkingHoursTable
                notEnteredHours={notEnteredHours}
                data={statusData?.nonWorkingHours || []}
                availableHours={availableHours}
                onDeleteRow={handleDeleteRow}
                isEditable={isEditable}
                onEditColumn={onEditColumn}
              />
            )}
          </ComponentWrapper>
        )}
      </div>

      {isEditable && (
        <div className={'flex w-full justify-end'}>
          <Button
            onClick={() => {
              if (availableHours <= totalHours || isStudent) {
                setIsSendToManagerModalOpen(true)
              } else {
                setIsNotEnoughHoursModalOpen(true)
              }
            }}
          >
            {t('working_hours.send_to_manager')}
          </Button>
        </div>
      )}
      <NotificationModal
        key={'send_to_manager'}
        isOpen={isSendToManagerModalOpen}
        onClose={() => setIsSendToManagerModalOpen(false)}
        title={'Pošalji voditelju'}
        subtitle={`Potvrđivanjem radnje Vaši upisani radni sati za mjesec ${dayjs()
          .set('month', Number(month) - 1)
          .format('MMMM')}, ${year}. godine bit će poslani voditelju na uvid i odobrenje. Stanje pratite u aplikaciji.`}
        primaryButtonText={t('shared.send') as string}
        secondaryButtonText={t('shared.give_up') as string}
        onPrimaryClick={handleSendWorkingHours}
        isLoading={isLoading}
        onSecondaryPress={() => setIsSendToManagerModalOpen(false)}
      />
      <NotificationModal
        key={'succesfully_sent'}
        isOpen={isSuccesfullySentModalOpen}
        onClose={() => setIsSuccesfullySentModalOpen(false)}
        title={'Uspješno poslano!'}
        subtitle={'Vaši sati su uspješno poslani voditelju na uvid.'}
      />
      <NotificationModal
        key={'not_complete_hours'}
        isOpen={isNotEnoughHoursModalOpen}
        onClose={() => setIsNotEnoughHoursModalOpen(false)}
        title={'Nedovoljan uneseni broj sati'}
        subtitle={
          'Kako biste poslali radne sate na uvid voditelju, mora biti ispunjena kvota od 100% unešenih radnih sati u željenom mjesecu.'
        }
      />
    </PageContent>
  )
}
