import { Table } from '@components/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { IImportChanges, ImportSelectState, ImportType } from '../../../types'
import { getImportColumnConfig, getImportTableData } from '../utils'

interface IImportTable {
  selectedView: ImportSelectState
  data?: IImportChanges<any>
  isLoading?: boolean
  refetch?: () => void
  type: ImportType
}

export const ImportTable: FC<IImportTable> = ({ selectedView, isLoading, data, type }) => {
  const { t } = useTranslation()

  const tableData = useMemo(() => getImportTableData(data, selectedView, type), [data, selectedView, type])
  const tableColumns = useMemo(() => getImportColumnConfig({ t, type }), [t, type])

  return (
    <section tw='mb-4 overflow-y-scroll bg-white max-h-[70svh]'>
      <Table
        isLoading={isLoading}
        columns={tableColumns}
        data={tableData}
        EmptyTable={() => {
          return (
            <div className={'flex h-full min-h-[600px]  w-full items-center justify-center rounded-r-[6px] bg-white'}>
              <div>{t('shared.no_data')}</div>
            </div>
          )
        }}
      />
    </section>
  )
}
