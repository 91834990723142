import { useDepartment } from '@hooks/api/department'
import { Chart as ChartJS } from 'chart.js'
import { useCallback, useEffect, useState } from 'react'
import { theme } from 'twin.macro'

export const colors = [
  '#FFCDD2',
  '#F8BBD0',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',
  '#BBDEFB',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
  '#C8E6C9',
  '#DCEDC8',
  '#F0F4C3',
  '#FFF9C4',
  '#FFF9C4',
  '#FFE0B2',
  '#FFCCBC',
]

export const supportColors = ['#606060', '#707070', '#888888', '#A0A0A0', '#C0C0C0', '#B0B0B0', '#C0C0C0', '#D3D3D3']

export const useColors = () => {
  const [colorMap, setColorMap] = useState<{ [key: string]: string }>({})
  const [supportColorMap, setSupportColorMap] = useState<{ [key: string]: string }>({})
  const { data, isLoading } = useDepartment(!Object.keys(colorMap).length)

  useEffect(() => {
    if (!isLoading && data?.data) {
      const departmentNames = data?.data
        .filter(v => v.operational)
        .map(v => v.name)
        .sort((a, b) => b.localeCompare(a))
      const cm = departmentNames.reduce((acc, val, idx) => {
        return { ...acc, [val]: colors[Math.floor((idx * colors.length) / departmentNames.length)] }
      }, {})
      setColorMap(cm)

      const supportDepartmentNames = data?.data
        .filter(v => v.support)
        .map(v => v.name)
        .sort((a, b) => b.localeCompare(a))
      const scm = supportDepartmentNames.reduce((acc, val, idx) => {
        return { ...acc, [val]: supportColors.reverse()[idx] }
      }, {})
      setSupportColorMap(scm)
    }
  }, [isLoading, data])

  const getColor = useCallback(
    (departmentName: string) => {
      return colorMap[departmentName] ?? supportColorMap[departmentName]
    },
    [colorMap, supportColorMap],
  )

  const generateRandomColors = useCallback(
    (chart: ChartJS, singleColor: boolean, labels: (string | number)[] = []) => {
      if (!chart.ctx) return
      if (singleColor) {
        const gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)

        gradient.addColorStop(0.33, theme`colors.primary` + '80')
        gradient.addColorStop(1, theme`colors.primary`)

        return gradient
      }

      return labels.map(val => {
        const gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top)

        const full = getColor(val.toString())

        gradient.addColorStop(0.33, full + '80')
        gradient.addColorStop(1, full)

        return gradient
      })
    },
    [getColor],
  )

  return { getColor, generateRandomColors }
}

export const supportColor = '#D7CCC8'
