import { CreateDepartmentDTO } from '@api/generated-api'
import { TextInput } from '@components/inputs'
import { Button, Tag } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface ICreateDepartmentFormProps {
  onCreate: (department: CreateDepartmentDTO) => void
  isSub?: boolean
}

const CreateDepartmentSchema = z.object({
  name: z.string().min(1, 'Required'),
  operational: z.boolean(),
  support: z.boolean(),
  channels: z.boolean().optional(),
})

export const CreateDepartmentForm: FC<ICreateDepartmentFormProps> = ({ onCreate, isSub = false }) => {
  const methods = useForm<CreateDepartmentDTO>({
    defaultValues: { name: '', operational: false, channels: false, support: false },
    resolver: zodResolver(CreateDepartmentSchema),
  })
  const { t } = useTranslation()
  const [operational, support] = methods.watch(['operational', 'support'])

  return (
    <Form methods={methods} onSubmit={onCreate}>
      <TextInput id='name' label={t('shared.name')} />
      {!isSub && (
        <div className={'flex gap-4'}>
          <Tag
            key={t('departments.operative')}
            onChange={() => methods.setValue('operational', !operational)}
            title={t('departments.operative')}
            checked={operational}
          >
            {t('departments.operative')}
          </Tag>
          <Tag
            checked={support}
            key={t('departments.support')}
            onChange={() => methods.setValue('support', !support)}
            title={t('departments.support')}
          >
            {t('departments.support')}
          </Tag>
        </div>
      )}
      <section tw='flex justify-start'>
        <Button tw='mt-4 w-full font-medium text-white uppercase' type='submit'>
          {t('shared.add')}
        </Button>
      </section>
    </Form>
  )
}
