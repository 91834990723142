import { ComponentWrapper, Table } from '@components/ui'
import { useEmployeeDetails } from '@hooks/api/employee'
import { useFormatCurrency } from '@hooks/useFormatCurrency'
import { useNavigation } from '@hooks/useNavigation'
import { useMe } from '@stores/me.store'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'

export const EmployeeWorkOverview = () => {
  const { id } = useParams()
  const { client } = useNavigation()
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()
  const { me } = useMe()
  const { data } = useEmployeeDetails(id)

  const tableData = (data?.data?.clients ?? []).map(e => ({ ...e, key: e.client_id })).sort((a, b) => b.hours - a.hours)

  const totalHours = Number(data?.data?.hours || 0) + Number(data?.data?.overtime_hours || 0)

  if (!tableData || tableData.length === 0) {
    return null
  }

  return (
    <ComponentWrapper>
      <div tw='flex items-center justify-between'>
        <span tw='mb-7 text-icons-dark text-2.5xl'>{t('employees.overview_per_client')}</span>
      </div>
      <div tw='overflow-scroll'>
        <Table
          columns={[
            {
              key: 'client',
              dataIndex: 'client',
              title: t('shared.client'),
            },
            {
              key: 'hours',
              dataIndex: 'hours',
              title: t('shared.worked_hours'),
              decoration: tw`w-[150px] pr-10`,
              rowDecoration: tw`pr-9`,
              render: row => (
                <>
                  {row['hours']} ({Math.round(((row['hours'] + Number(row['overtime_hours'] || 0)) * 100) / totalHours)}
                  %)
                </>
              ),
            },
            {
              key: 'overtime',
              dataIndex: 'overtime',
              title: t('shared.overtime'),
              decoration: tw`w-[150px] pr-10`,
              rowDecoration: tw`pr-9`,
              hidden: !me?.companySettings?.hasOvertime,
              render: row => row['overtime_hours'] ?? '-',
            },
            {
              key: 'total',
              dataIndex: 'total',
              title: t('shared.total'),
              hidden: !me?.companySettings?.hasOvertime,
              decoration: tw`w-[150px] pr-10`,
              rowDecoration: tw`pr-9`,
              render: row => (
                <>{`${Number(row['hours'] || 0) + Number(row['overtime_hours'] || 0)} (${Math.round(
                  ((row['hours'] + Number(row['overtime_hours'] || 0)) * 100) / totalHours,
                )}%)`}</>
              ),
            },
            {
              key: 'hourly_wage',
              dataIndex: 'hourly_wage',
              title: t('shared.hourly_wage'),
              decoration: tw`w-[150px] pr-10`,
              rowDecoration: tw`pr-9`,
              render: data => (
                <>
                  {data['hours'] > 0
                    ? formatCurrency((data['salary'] || 0) / (data['hours'] + Number(data['overtime_hours'] || 0)))
                    : 0}
                </>
              ),
            },
          ]}
          data={tableData}
          onRowClick={client}
        />
      </div>
    </ComponentWrapper>
  )
}
