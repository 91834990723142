import { FC } from 'react'
import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'

export interface ISelectButton {
  isActive: boolean
  onClick: () => void
  title: string
  total?: number
  decoration?: TwStyle | boolean
}

export const ImportSelectButton: FC<ISelectButton> = ({ isActive, onClick, title, total, decoration }) => {
  return (
    <button
      onClick={onClick}
      tw='flex cursor-pointer justify-between bg-white py-4 px-7 transition-colors hover:bg-zinc-100'
      css={[isActive && tw`bg-primary/10`, total == undefined && tw`items-center`, decoration]}
      type='button'
    >
      <section tw='flex items-center gap-8'>
        <span tw='text-lg'>{title}</span>
        {total != undefined && <span tw={'font-normal text-lg'}>{total}</span>}
      </section>
    </button>
  )
}
