import { exportSalariesTemplate, exportUsersTemplate } from '@api/export'
import { uploadEmployeeImportChangesTemplate, uploadSalaryImportChangesTemplate } from '@api/files'
import Assets from '@assets/assets'
import { useEmployeeTemplate } from '@hooks/api/template/useEmployeeTemplate'
import { useSalaryTemplate } from '@hooks/api/template/useSalaryTemplate'
import { navigateRoutes } from '@routes/navigate'
import { useMe } from '@stores/me.store'
import { FC, RefObject, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { ImportInput } from 'src/components/Import/ImportInput/ImportInput'
import 'twin.macro'
import tw from 'twin.macro'
import { CreateClientModal } from '../../../pages/Clients/Components/CreateClientsModal'
import { CreateEmployeeDrawer } from '../../../pages/Employees/Components/CreateEmployeeDrawer'
import { ImportType } from '../../../types'
import { ConfirmationModal } from '../../ConfirmationModal'
import { Button } from '../Button/Button'
import { Drawer } from '../Drawer/Drawer'
import { Dropdown } from '../Dropdown/Dropdown'
import { Filter } from './Filter'

export const Header: FC = () => {
  const { me } = useMe()
  const [isCreateClientOpen, setIsCreateClientOpen] = useState<boolean>(false)
  const [isCreateEmployeeOpen, setIsCreateEmployeeOpen] = useState<boolean>(false)
  const [importClientsRef, setImportClientsRef] = useState<RefObject<HTMLInputElement> | undefined>(undefined)
  const [importEmployeesRef, setImportEmployeesRef] = useState<RefObject<HTMLInputElement> | undefined>(undefined)
  const [importSalariesRef, setImportSalariesRef] = useState<RefObject<HTMLInputElement> | undefined>(undefined)
  const { importTemplate: importEmployeeTemplate } = useEmployeeTemplate()
  const { importTemplate: importSalariesTemplate } = useSalaryTemplate()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isVisible = useMemo(
    () =>
      !pathname.includes('/working-hours') && !pathname.includes('/account') && !pathname.includes('/edit-departments'),
    [pathname],
  )
  const isEmployeeList = pathname === '/employees'
  const isClientsList = pathname === '/clients'
  const isDepartments = pathname === '/departments'
  const isCompany = pathname === '/company'
  const employeePatern = /(?<=employees\/)\d+/
  const clientPattern = /(?<=clients\/)\d+/

  const employeeMatch = pathname.match(employeePatern)
  const clientMatch = pathname.match(clientPattern)

  if (!isVisible) {
    return null
  }

  return (
    <header className='flex h-18 items-center justify-between border-b border-accent bg-background px-7'>
      {employeeMatch || clientMatch ? (
        <div
          className='cursor-pointer'
          onClick={() => {
            history.go(-1)
          }}
        >
          <img src={Assets.ArrowLeft} className='transition-transform' alt={'arrow-back'} />
        </div>
      ) : (
        <div></div>
      )}
      <div className={'flex items-center gap-4'}>
        {isClientsList && (
          <div className={'flex gap-4'}>
            {/* <Button
              buttonType={'secondary'}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                if (importClientsRef?.current) {
                  importClientsRef.current?.click()
                }
              }}
            >
              {t('clients.import_clients')}
            </Button> */}
            <Button onClick={() => setIsCreateClientOpen(true)}>{t('clients.add_client')}</Button>
          </div>
        )}
        {isEmployeeList && (
          <div className={'flex gap-4'}>
            {me?.isAdmin && (
              <Dropdown
                variant='secondary'
                trigger='click'
                items={[
                  {
                    key: 'export-employee-template',
                    title: t(`import.template.export_template`) as string,
                    subheader: t(`import.employees`) as string,
                    onClick: exportUsersTemplate,
                  },
                  {
                    key: 'import-employee-template',
                    title: t(`import.template.import_template`) as string,
                    onClick: (item, e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (importEmployeesRef?.current) {
                        importEmployeesRef.current?.click()
                      }
                    },
                  },
                  {
                    key: 'export-salaries-template ',
                    title: t(`import.template.export_template`) as string,
                    subheader: t(`import.payroll`) as string,
                    onClick: exportSalariesTemplate,
                  },
                  {
                    key: 'import-salaries-template',
                    title: t(`import.template.import_template`) as string,
                    onClick: (item, e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (importSalariesRef?.current) {
                        importSalariesRef.current?.click()
                      }
                    },
                  },
                ]}
                containerStyle={tw`flex items-center justify-between min-w-[200px]`}
              >
                <div className='flex items-center justify-between rounded-md'>
                  <span tw='font-medium text-[14px]'>{t(`import.template.management`).toUpperCase()}</span>
                </div>
              </Dropdown>
            )}

            <Button onClick={() => setIsCreateEmployeeOpen(true)}>{t('employees.add_employee')}</Button>
          </div>
        )}
        {isDepartments && (
          <Link to={navigateRoutes.editDepartments()}>
            <Button onClick={() => {}}>{t('departments.edit_departments')}</Button>
          </Link>
        )}
        {isCompany && (
          <div className={'flex gap-4'}>
            <Button onClick={() => {}}>{t('company.import_data')}</Button>
          </div>
        )}
        <i onClick={() => setIsFilterOpen(true)} tw='ml-4 cursor-pointer text-primary' className='fa fa-filter' />
      </div>

      <Drawer
        title={t('global_filter.title') as string}
        placement='right'
        isOpen={isFilterOpen}
        close={() => setIsFilterOpen(false)}
      >
        <Filter closeDrawer={() => setIsFilterOpen(false)} />
      </Drawer>
      <CreateEmployeeDrawer
        isCreateEmployeeOpen={isCreateEmployeeOpen}
        setIsCreateEmployeeOpen={setIsCreateEmployeeOpen}
      />
      <CreateClientModal isOpen={isCreateClientOpen} close={() => setIsCreateClientOpen(false)} />
      {/* <ImportInput
        type={ImportType.CLIENTS}
        onUpload={async aa => {
          console.log('upload clients', aa)
        }}
        setInputRef={setImportClientsRef}
        tempInputRef={importClientsRef}
        onUploadTemplate={uploadEmployeeImportChangesTemplate}
      /> */}
      {me?.isAdmin && (
        <ImportInput
          type={ImportType.EMPLOYEES}
          onUpload={importEmployeeTemplate.mutateAsync}
          setInputRef={setImportEmployeesRef}
          onUploadTemplate={uploadEmployeeImportChangesTemplate}
          tempInputRef={importEmployeesRef}
        />
      )}
      {me?.isAdmin && (
        <ImportInput
          type={ImportType.SALARIES}
          onUpload={importSalariesTemplate.mutateAsync}
          setInputRef={setImportSalariesRef}
          onUploadTemplate={uploadSalaryImportChangesTemplate}
          tempInputRef={importSalariesRef}
        />
      )}
    </header>
  )
}
