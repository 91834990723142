import { axiosApiInstance } from '@api/axiosInstance'
import { useMutation } from '@tanstack/react-query'

export const useSalaryTemplate = () => {
  const { salary } = axiosApiInstance

  const checkImportTemplate = useMutation({
    mutationFn: salary.salaryControllerCheckImportTemplate,
  })

  const importTemplate = useMutation({
    mutationFn: salary.salaryControllerImportTemplate,
  })

  const exportTemplate = useMutation({
    mutationFn: salary.salaryControllerExportUsers,
  })

  return {
    exportTemplate,
    checkImportTemplate,
    importTemplate,
  }
}
