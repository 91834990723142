import { FC, InputHTMLAttributes } from 'react'
import 'twin.macro'

interface IRadioOption {
  key: string
  title: string
}

interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  options: IRadioOption[]
  selectedOption?: string | number
  onChange?: (val: string) => void
}

export const Radio: FC<IRadioProps> = ({ options, selectedOption, onChange, id, ...inputProps }) => {
  const handleChange = (val: string | number) => {
    onChange?.(val)
  }

  return (
    <div className='flex items-center gap-6'>
      {options.map(option => (
        <div key={`${id}+${option.key}`} className='mr-2 form-check flex items-center'>
          <input
            {...inputProps}
            type='radio'
            checked={option.key === selectedOption}
            name={id}
            onChange={e => {
              handleChange(option.key)
            }}
            id={`${id}+${option.key}`}
            className='appearance-none rounded-full size-5 border-2 border-gray-300 checked:border-blue-600 bg-white checked:bg-white relative cursor-pointer mr-2 after:content-[""] after:size-[11px] after:bg-blue-600 after:rounded-full after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:hidden checked:after:block transition-colors'
          />
          <div className='form-check-label inline-block text-gray-800'>{option.title}</div>
        </div>
      ))}
    </div>
  )
}
