import { ColorContext } from '@utils/colorContext'
import { supportColor } from '@utils/randomColor'
import { FC, useContext } from 'react'
import 'twin.macro'
import tw from 'twin.macro'

interface ILegendDotProps {
  title: string
  showDot?: boolean
  onClick?: any
  support?: boolean
  hasMargin?: boolean
}

export const LegendDot: FC<ILegendDotProps> = ({ title, showDot = true, onClick = undefined, hasMargin = true }) => {
  const { getColor } = useContext(ColorContext)

  return (
    <div tw='flex items-center' onClick={onClick} css={[hasMargin ? tw`mr-3` : tw`mr-0`]}>
      <span
        tw='invisible mr-1 block h-3 w-3 rounded-full min-w-[0.75rem]'
        css={[showDot && tw`visible`]}
        style={{ background: getColor(title) || supportColor }}
      />
      {title}
    </div>
  )
}
