import { FileRes } from '@interfaces/files'
import { IImportChanges } from 'src/types'
import { post } from './axiosInstance'

export const uploadFile = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return post<FileRes, FileRes>('/files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const uploadFiles = (files: File[], setProgress?: any) => {
  const formData = new FormData()
  for (const file of files) {
    formData.append('files', file)
  }

  return post<Array<FileRes>, Array<FileRes>>('/files/multiple', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => (setProgress ? setProgress(progressEvent) : null),
  })
}

export const uploadEmployeeImportChangesTemplate = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return post<FileRes, IImportChanges<any>>('/employee/import/template/check', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const uploadSalaryImportChangesTemplate = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return post<FileRes, IImportChanges<any>>('/salary/template/check', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
