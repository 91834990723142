import { useColors } from '@utils/randomColor'
import { Chart as ChartJS } from 'chart.js'
import { FC, useEffect, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import 'twin.macro'
import { theme } from 'twin.macro'

interface IBarChartProps {
  labels?: (string | number)[]
  data?: number[]
  title?: string
  loading?: boolean
  singleColor?: boolean
}

export const BarChart: FC<IBarChartProps> = ({ labels, data, title, loading = false, singleColor = false }) => {
  const chartRef = useRef<ChartJS>(null)
  const [colors, setColors] = useState<any>([])
  const { generateRandomColors } = useColors()

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    setColors(() => generateRandomColors(chart, singleColor, labels))
  }, [labels, generateRandomColors, singleColor])

  return (
    <Chart
      ref={chartRef}
      type='bar'
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            border: {
              display: false,
            },
            grid: {
              display: true,
              drawTicks: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: 5,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            tension: 0.2,
            fill: 'origin',
            label: title,
            data: data,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: theme`colors.primary` as string,
            backgroundColor: colors,
          },
        ],
      }}
    />
  )
}
