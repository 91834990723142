import { FC } from 'react'
import 'twin.macro'
import { PaginationItem } from './PaginationItem'

export interface IPaginationProps {
  total: number
  currentPage: number
  perPage?: number
  onPageChange: (page: number) => void
  onPerPageChange?: (perPage: number) => void
}

export const Pagination: FC<IPaginationProps> = props => {
  const { total, currentPage, perPage } = props
  const pages = Math.max(1, Math.ceil(Number((total || 0) / (perPage || 1))))

  const renderItems = Array(pages)
    .fill(0)
    .map((_, index) => {
      const page = index + 1

      if (page === 1 || page === pages) {
        return (
          <PaginationItem {...props} page={page} key={page}>
            {page}
          </PaginationItem>
        )
      }

      if (pages <= 5 || (currentPage <= 3 && page <= 3)) {
        return (
          <PaginationItem {...props} page={page} key={page}>
            {page}
          </PaginationItem>
        )
      }

      if (page >= currentPage - 1 && page <= currentPage + 1) {
        return (
          <PaginationItem {...props} page={page} key={page}>
            {page}
          </PaginationItem>
        )
      }

      if (
        // (currentPage > 3 || currentPage - 1 < pages - 3) &&
        pages > 5 &&
        (page == currentPage - 2 ||
          (currentPage <= 3 && page == currentPage + 3) ||
          (currentPage > 3 && page == currentPage + 2))
      ) {
        const jump = page == currentPage - 2 ? 'jump-back' : 'jump-forward'
        return (
          <PaginationItem {...props} page={jump} key={jump}>
            <i tw='text-gray-500' className='fa fa-ellipsis-h'></i>
          </PaginationItem>
        )
      }
      return null
    })

  return (
    <ul tw='flex'>
      <PaginationItem {...props} page='previous' key='previous'>
        <i tw='text-gray-500' className='fa fa-chevron-left'></i>
      </PaginationItem>
      {renderItems}
      <PaginationItem {...props} page='next' key='next'>
        <i tw='text-gray-500' className='fa fa-chevron-right'></i>
      </PaginationItem>
    </ul>
  )
}
