import 'twin.macro'
import tw from 'twin.macro'
import { ImportSelectButton, ISelectButton } from './ImportSelectButton'

interface ISelectViewFactory<T> {
  selectedView: T
  setSelectedView: (sv: T) => void
  settings: Array<Omit<ISelectButton, 'isActive' | 'onClick'> & { view: T }>
  isRow?: boolean
}

export const ImportSelectViewFactory: <T>(p: ISelectViewFactory<T>) => React.ReactElement = ({
  selectedView,
  setSelectedView,
  settings,
}) => {
  return (
    <section tw='flex rounded-t-xl'>
      {settings.map((bs, idx, all) => (
        <ImportSelectButton
          key={'select-button-' + idx}
          {...bs}
          decoration={(idx === 0 && tw`rounded-tl-lg`) || (all.length - 1 === idx && tw`rounded-tr-lg`)}
          isActive={selectedView == bs.view}
          onClick={() => setSelectedView(bs.view)}
        />
      ))}
    </section>
  )
}
