import { Modal } from '@components/ui'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { ICreateClient } from '../../../../types/client'
import { CreateClientForm } from '../Forms/CreateClientForm'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createClient } from '@api/client'
import { ConfirmationModal } from 'src/components/ConfirmationModal'

interface ICreateClientModalProps {
  isOpen: boolean
  close: () => void
}

export const CreateClientModal: FC<ICreateClientModalProps> = ({ isOpen, close }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean | string>(false)

  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { mutateAsync: createClientAsync } = useMutation({
    mutationFn: createClient,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['clients-list'] })
    },
  })

  const createClientFn = useCallback(
    async (client: ICreateClient) => {
      const resp = await createClientAsync(client)
      close()
      if (resp?.data?.name) {
        setIsConfirmationModalOpen(resp?.data?.name)
      }
    },
    [close, createClientAsync],
  )

  return (
    <>
      <Modal isOpen={isOpen} close={close}>
        <div tw='relative flex flex-col rounded-md border bg-white px-10 py-8 shadow-sm min-w-[350px] max-w-[500px]'>
          <i
            tw='absolute top-3 right-5 cursor-pointer text-lg text-icons-dark'
            className='fa fa-times'
            onClick={close}
          />
          <h2 tw='mb-4 block text-icons-dark text-2.5xl'>{t('clients.add_client')}</h2>
          <CreateClientForm onCreate={createClientFn} />
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={!!isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={`Klijent '${isConfirmationModalOpen}' uspješno dodan`}
      />
    </>
  )
}
