export type ComponentSize = 'large' | 'medium' | 'small' | 'mini'
export type ComponentDirection = 'horizontal' | 'vertical'

export type WorkingHoursStatus = 'APPROVED' | 'REJECTED'
export type WorkingHoursType = 'NORMAL' | 'WFH' | 'OVERTIME'
export type NonWorkingHoursType = 'MATERNITY' | 'SICK' | 'REST' | 'VACATION'

export enum WorkingHoursTypeEnum {
  NORMAL = 'NORMAL',
  WFH = 'WFH',
  OVERTIME = 'OVERTIME',
  REST = 'REST',
}

export enum NonWorkingHoursTypeEnum {
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  VACATION = 'VACATION',
}
export type IWorkingHoursActiveTableData = {
  id: number | string
  date: string
  hours: number
  percentage: number
  date_approved: string
  date_sent: string
  employee_id: number
  status: WorkingHoursStatus
  note?: string
}

export enum ImportType {
  CLIENTS = 'clients',
  EMPLOYEES = 'employees',
  SALARIES = 'salaries',
}

export const enum ImportSelectState {
  CHANGED = 'changed',
  REMOVED = 'removed',
  ADDED = 'added',
  ALL = 'all',
}

export interface IImportRowGeneric {
  status?: string
  changed?: {
    key: string
    new: string
    old?: string
    status?: string
  }[]
}

export type IImportRow<T> = IImportRowGeneric & T

export interface IImportChanges<T> {
  all: IImportRow<T>[]
  toBeAdded?: IImportRow<T>[]
  toBeDeleted?: IImportRow<T>[]
  toBeUpdated?: IImportRow<T>[]
}

export type WorkingHours = {
  id: number
  hours: number | string
  name: string
  type: WorkingHoursType
  working_hours_id?: number
}

export type NonWorkingHours = {
  id: number
  hours: number | string
  name: string
  type: NonWorkingHoursType
  working_hours_id?: number
}

export type IWorkingHoursArchiveTableData = {
  date: string
  date_approved: string
  date_sent: string
  employee_id: number
  id: number
  note?: string
  status: WorkingHoursStatus
}

export type EmployeeClients = {
  createdAt: string
  deletedAt?: string
  id: number
  name: string
  snowflake: string
  updatedAt: string
}
