import { Collapse, ComponentWrapper, Dropdown, Table } from '@components/ui'
import { useGetClientWorkingHoursExistingDates } from '@hooks/api/working-hours-management/useGetClientWorkingHoursExistingDates'
import { useGetClientWorkingHoursManagement } from '@hooks/api/working-hours-management/useGetClientWorkingHoursManagement'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedTabs } from '../../../../components/Inputs/SegmentedTabs/SegmentedTabs'
import { CollapsibleTitle } from '../CollapsibleTitle'
import tw from 'twin.macro'

export const ClientsWorkingHoursManagementTable = ({ departmentId }: { departmentId?: number }) => {
  const [openCollapsibleId, setOpenCollapsibleId] = useState<number | undefined>(undefined)
  const [yearFilter, setYearFilter] = useState<string | null>(null)
  const [monthFilter, setMonthFilter] = useState<string | null>(null)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'ASC' })
  const { t } = useTranslation()
  const { data: clientData = [] } = useGetClientWorkingHoursManagement({ departmentId, date: monthFilter })
  const { data: datesData } = useGetClientWorkingHoursExistingDates({ departmentId })

  const availableYears = [...new Set((datesData || [])?.map(date => dayjs(date).year()))].sort((a, b) => b - a)
  const availableDates = useMemo(
    () =>
      [...new Set((datesData || [])?.map(date => dayjs(date).format('YYYY-MM-DD')))].sort((a, b) =>
        dayjs(b).isAfter(dayjs(a)) ? -1 : 1,
      ),
    [datesData],
  )

  const segmentOptions = useMemo(
    () =>
      availableYears.map(y => ({
        label: y.toString(),
        value: y.toString(),
      })),
    [availableYears],
  )

  const dateOptions = useMemo(
    () =>
      availableDates
        .filter(d => (yearFilter ? d.includes(yearFilter) : true))
        .map(y => ({
          title: dayjs(y).format('MMMM'),
          value: y.toString(),
          key: y.toString(),
          onClick: () => setMonthFilter(y.toString()),
        })),
    [availableDates, yearFilter],
  )

  useEffect(() => {
    if (!yearFilter && segmentOptions.length > 0) {
      setYearFilter(segmentOptions[0].label)
    }
  }, [yearFilter, segmentOptions])

  useEffect(() => {
    if ((!monthFilter && dateOptions.length > 0) || !monthFilter?.includes(yearFilter)) {
      setMonthFilter(dateOptions?.[0]?.value)
    }
  }, [dateOptions, monthFilter, yearFilter])

  return (
    <ComponentWrapper className={'overflow-scroll'}>
      <div className={'flex items-center justify-between'}>
        {(segmentOptions || []).length > 1 && (
          <SegmentedTabs
            containerProps={{ className: 'mb-6' }}
            options={segmentOptions}
            value={yearFilter}
            onChange={(val: any) => {
              setYearFilter(val === yearFilter ? null : val)
            }}
          />
        )}
        {(dateOptions || []).length > 1 && (
          <Dropdown items={dateOptions} trigger='click'>
            <span tw='flex w-full overflow-hidden whitespace-nowrap'>{dayjs(monthFilter).format('MMMM')}</span>
          </Dropdown>
        )}
      </div>

      {clientData.map(client => {
        return (
          <Collapse
            key={client.clientId}
            hasBorder={!(openCollapsibleId === client.clientId)}
            onClick={() => setOpenCollapsibleId(prevId => (prevId === client.clientId ? undefined : client.clientId))}
            isOpen={openCollapsibleId === client.clientId}
            Title={() => (
              <CollapsibleTitle
                title={client.clientName}
                rightContent={
                  <div className='text-right'>
                    <span className='text-base font-medium leading-[17.60px] text-neutral-900'>{client.hours} </span>
                    <span className='text-base font-normal leading-[17.60px] text-gray-600'>sati</span>
                  </div>
                }
              />
            )}
          >
            <Table
              order={order}
              columns={[
                {
                  key: 'name',
                  dataIndex: 'name',
                  title: t('working_hours_management.name'),
                  order: setOrder,
                  render: tableData => <>{tableData.employeeName}</>,
                },
                {
                  key: 'hours',
                  dataIndex: 'hours',
                  title: t('working_hours_management.working_hours'),
                  decoration: tw`w-[150px]`,
                  order: setOrder,
                  render: tableData => <>{tableData['hours'] || 0}</>,
                },
                {
                  key: 'percentage',
                  dataIndex: 'percentage',
                  title: t('working_hours_management.percentage'),
                  decoration: tw`w-[150px]`,
                  order: setOrder,
                  render: tableData => (
                    <>{((Number(tableData['hours'] || 0) / Number(client.hours)) * 100).toFixed(0)}%</>
                  ),
                },
                {
                  key: 'date_approved',
                  decoration: tw`w-[150px]`,
                  dataIndex: 'date_approved',
                  title: t('working_hours_management.approved_at'),
                  order: setOrder,
                  render: tableData => (
                    <>
                      {dayjs(tableData['date_approved']).isValid()
                        ? dayjs(tableData['date_approved']).format('DD.MM.YYYY')
                        : '-'}
                    </>
                  ),
                },
              ]}
              data={(client?.employees || [])?.map(emp => ({ ...emp, key: emp.employeeId })).sort(orderData)}
            />
          </Collapse>
        )
      })}
    </ComponentWrapper>
  )
}
