import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { IImportChanges, ImportSelectState, ImportType } from '../../../types'
import { getImportTableData } from '../utils'
import { ImportSelectViewFactory } from './ImportSelectViewFactory'

interface ISelectView {
  selectedView: ImportSelectState
  setSelectedView: (sv: ImportSelectState) => void
  importChanges?: IImportChanges<any>
  type: ImportType
}

export const ImportSelectView: FC<ISelectView> = ({ selectedView, setSelectedView, importChanges, type }) => {
  const { t } = useTranslation()

  if (!importChanges) return null

  return (
    <ImportSelectViewFactory
      selectedView={selectedView}
      setSelectedView={setSelectedView}
      settings={[
        {
          view: ImportSelectState.CHANGED,
          title: t('import.changed'),
          total: getImportTableData(importChanges, ImportSelectState.CHANGED, type)?.length ?? 0,
        },
        {
          view: ImportSelectState.ADDED,
          title: t('import.added'),
          total: getImportTableData(importChanges, ImportSelectState.ADDED, type)?.length ?? 0,
        },
        {
          view: ImportSelectState.REMOVED,
          title: t('import.removed'),
          total: getImportTableData(importChanges, ImportSelectState.REMOVED, type)?.length ?? 0,
        },
        {
          view: ImportSelectState.ALL,
          title: t('import.all'),
          total: getImportTableData(importChanges, ImportSelectState.ALL, type)?.length ?? 0,
        },
      ]}
    />
  )
}
