import { getExcelFile } from './axiosInstance'

// export const exportContent = (contentId: IdParam, content: IContent) =>
//   getExcelFile(`export/content/${contentId}`, createContentExportFileName(content))

// export const exportUser = (userId: IdParam, name?: string) =>
//   getExcelFile(`export/user/${userId}`, createExportFileName(name) ?? 'korisnik')

// export const exportUsers = (userIds: IdParam[]) =>
//   getExcelFile(`export/users?userIds=${userIds.join(',')}`, 'korisnici')

export const exportUsersTemplate = () => getExcelFile(`employee/export`, 'employee-template')

export const exportSalariesTemplate = () => getExcelFile(`salary/export`, 'salary-template')
