import { Api } from '@api/generated-api'
import { IApiError } from '@interfaces/apiError'
import { useGlobalFilter } from '@stores/globalFilter.store'
import { useMe } from '@stores/me.store'
import { notificationStore } from '@stores/notification.store'
import { env } from '@utils/environment'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

export const axiosInstance = axios.create({
  baseURL: env.BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const getExcelFile = (url: string, fileName: string) =>
  axiosInstance({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })

axiosInstance.interceptors.request.use(
  config => requestInterceptorHandler(config),
  err => requestErrorHandler(err),
)

axiosInstance.interceptors.response.use(
  res => responseSuccessHandler(res),
  err => responseErrorHandler(err),
)

export const axiosApiInstance = new Api({
  ...axiosInstance.defaults,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  interceptors: axiosInstance.interceptors,
})

const requestInterceptorHandler = (config: AxiosRequestConfig<any>) => {
  const {
    filter: { year, month, quartal },
  } = useGlobalFilter.getState()

  const filter = {
    ...config.params,
    months: quartal.length
      ? quartal.reduce((acc: number[], v) => [...acc, 3 * v - 2, 3 * v - 1, 3 * v], []).join(',')
      : month.join(','),
    year,
  }

  config.params = filter
  return config
}

const requestErrorHandler = (err: any) => {
  return Promise.reject(err)
}

const responseSuccessHandler = (res: AxiosResponse<any, any>) => {
  return res
}

const responseErrorHandler = (err: AxiosError) => {
  if (err?.response?.status == 401) {
    useMe.getState().logout()
  }
  if (!err.response?.data) {
    return err
  }
  const errorData = err.response?.data as IApiError
  if (errorData.method != 'POST') {
    return err
  }
  const { addNotification } = notificationStore.getState()
  addNotification({ title: 'Error', content: errorData.message, status: 'error' })

  return err
}

const { get: axiosGet, post: axiosPost, put: axiosPut, delete: axiosDelete, patch: axiosPatch } = axiosInstance

export const get = <T>(url: string, config?: AxiosRequestConfig<any> | undefined) =>
  axiosGet<T, AxiosResponse<T, IApiError>, IApiError>(url, config)

export const post = <T, K>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
  axiosPost<T, AxiosResponse<K, IApiError>, IApiError>(url, data, config)

export const put = <T>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
  axiosPut<T, AxiosResponse<T, IApiError>, IApiError>(url, data, config)

export const patch = <T>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
  axiosPatch<T, AxiosResponse<T, IApiError>, IApiError>(url, data, config)

export const del = <T>(url: string, config?: AxiosRequestConfig<any> | undefined) =>
  axiosDelete<T, AxiosResponse<T, IApiError>, IApiError>(url, config)
