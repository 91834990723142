import { Button, Drawer } from '@components/ui'
import { AxiosResponse } from 'axios'
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { IImportChanges, ImportSelectState, ImportType } from '../../../types'
import { ImportSelectView } from '../ImportSelectView/ImportSelectView'
import { ImportTable } from '../ImportTable/ImportTable'

interface IContentDrawer {
  importChanges?: IImportChanges<any>
  setImportChanges: Dispatch<SetStateAction<IImportChanges<any> | undefined>>
  fileName?: string
  onUpload: (importChanges: IImportChanges<any>) => Promise<AxiosResponse<any, any>>
  type: ImportType
}

export const ImportChangesDrawer: FC<IContentDrawer> = ({
  setImportChanges,
  importChanges,
  fileName,
  onUpload,
  type,
}) => {
  const [selectedView, setSelectedView] = useState<ImportSelectState>(ImportSelectState.ALL)
  const { t } = useTranslation()
  const close = useCallback(() => {
    setImportChanges(undefined)
  }, [setImportChanges])

  const handleUploadChanges = useCallback(async () => {
    if (!importChanges) return
    await onUpload(importChanges)
    close()
  }, [close, importChanges, onUpload])

  const hasChanges = useMemo(
    () =>
      (importChanges?.toBeUpdated || []).length > 0 ||
      (importChanges?.toBeAdded || []).length > 0 ||
      (importChanges?.toBeDeleted || []).length > 0,
    [importChanges],
  )

  return (
    <Drawer
      isOpen={!!importChanges}
      close={close}
      placement='right'
      title={t('import.reviewChanges') as string}
      subtitle={fileName}
      headerTwStyle={'bg-background'}
      drawerTwStyle={tw`w-full max-w-none pb-0`}
    >
      <section tw='flex h-full flex-col rounded bg-background px-6 py-4'>
        <ImportSelectView
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          importChanges={importChanges}
          type={type}
        />
        <ImportTable selectedView={selectedView} data={importChanges} isLoading={false} type={type} />
        <section className={'flex items-center justify-end gap-4'}>
          <Button buttonType={'secondary'} onClick={close}>
            {t('import.give_up')}
          </Button>
          <Button disabled={!hasChanges} onClick={handleUploadChanges}>
            {t('import.save_changes')}
          </Button>
        </section>
      </section>
    </Drawer>
  )
}
