import tw from 'twin.macro'

interface IEditDeleteRenderProps {
  onEdit?: () => void
  onDelete?: () => void
  onAdd?: () => void
  isSub?: boolean
}

export const EditDeleteRender = ({ onEdit, onDelete, onAdd, isSub }: IEditDeleteRenderProps) => {
  return (
    <div className={'mr-4 flex items-center justify-end gap-2 opacity-0 transition-all group-hover/row:opacity-100'}>
      {!!onAdd && (
        <div
          className={'relative cursor-pointer text-zinc-400 transition-colors hover:text-zinc-600'}
          onClick={e => {
            e.stopPropagation()
            onAdd()
          }}
          css={[isSub && tw`invisible`]}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7468 9.41513 20.7185 6.93705 18.8907 5.10927C17.063 3.28149 14.5849 2.25323 12 2.25ZM15.75 12.75H12.75V15.75C12.75 15.9489 12.671 16.1397 12.5303 16.2803C12.3897 16.421 12.1989 16.5 12 16.5C11.8011 16.5 11.6103 16.421 11.4697 16.2803C11.329 16.1397 11.25 15.9489 11.25 15.75V12.75H8.25C8.05109 12.75 7.86033 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86033 11.329 8.05109 11.25 8.25 11.25H11.25V8.25C11.25 8.05109 11.329 7.86032 11.4697 7.71967C11.6103 7.57902 11.8011 7.5 12 7.5C12.1989 7.5 12.3897 7.57902 12.5303 7.71967C12.671 7.86032 12.75 8.05109 12.75 8.25V11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75Z' />
          </svg>
        </div>
      )}
      {!!onEdit && (
        <div
          className={'relative cursor-pointer text-zinc-400 transition-colors hover:text-zinc-600'}
          onClick={e => {
            e.stopPropagation()
            onEdit()
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
            <path d='M21.3103 6.87817L17.1216 2.68848C16.9823 2.54916 16.8169 2.43864 16.6349 2.36324C16.4529 2.28783 16.2578 2.24902 16.0608 2.24902C15.8638 2.24902 15.6687 2.28783 15.4867 2.36324C15.3047 2.43864 15.1393 2.54916 15 2.68848L3.43969 14.2497C3.2998 14.3885 3.18889 14.5537 3.11341 14.7357C3.03792 14.9178 2.99938 15.113 3.00001 15.31V19.4997C3.00001 19.8976 3.15804 20.2791 3.43935 20.5604C3.72065 20.8417 4.10218 20.9997 4.50001 20.9997H8.6897C8.88675 21.0004 9.08197 20.9618 9.26399 20.8863C9.44602 20.8109 9.61122 20.6999 9.75001 20.56L21.3103 8.99973C21.4496 8.86044 21.5602 8.69507 21.6356 8.51306C21.711 8.33105 21.7498 8.13596 21.7498 7.93895C21.7498 7.74194 21.711 7.54686 21.6356 7.36485C21.5602 7.18284 21.4496 7.01746 21.3103 6.87817ZM18 10.1885L13.8103 5.99973L16.0603 3.74973L20.25 7.93848L18 10.1885Z' />
          </svg>
        </div>
      )}
      {!!onDelete && (
        <div
          className={'relative cursor-pointer text-zinc-400 transition-colors hover:text-zinc-600'}
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
            <path d='M19.2107 3.6837C19.5002 3.6837 19.7896 3.97317 19.7896 4.26264V5.42054C19.7896 5.7462 19.5002 5.99949 19.2107 5.99949H4.15805C3.83239 5.99949 3.5791 5.7462 3.5791 5.42054V4.26264C3.5791 3.97317 3.83239 3.6837 4.15805 3.6837H8.50015L8.82581 3.03238C8.93436 2.74291 9.29621 2.5258 9.58568 2.5258H13.7469C14.0363 2.5258 14.362 2.74291 14.5067 3.03238L14.8686 3.6837H19.2107ZM5.49686 19.4238L4.737 7.15738H18.6317L17.8357 19.4238C17.7995 20.3284 17.0034 21.0521 16.0988 21.0521H7.23371C6.3291 21.0521 5.53305 20.3284 5.49686 19.4238Z' />
          </svg>
        </div>
      )}
    </div>
  )
}
