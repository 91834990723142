import Assets from '@assets/assets'
import { Collapse, ComponentWrapper, Table } from '@components/ui'
import { useGetArchivedWorkingHoursManagement } from '@hooks/api/working-hours-management/useGetArchivedWorkingHoursManagement'
import { useGetClientWorkingHoursExistingDates } from '@hooks/api/working-hours-management/useGetClientWorkingHoursExistingDates'
import { useGetNumberOfEmployeesInDepartment } from '@hooks/api/working-hours-management/useGetNumberOfEmployeesInDepartment'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedTabs } from '../../../../components/Inputs/SegmentedTabs/SegmentedTabs'
import { IWorkingHoursActiveTableData } from '../../../../types'
import { CollapsibleTitle } from '../CollapsibleTitle'
import { HoursManagementContentModal } from '../HoursManagementContentModal'
import { useMe } from '@stores/me.store'
import { UpdateEmployeeDtoHoursTypeEnum } from '@api/generated-api'
import tw from 'twin.macro'

export const ArchiveWorkingHoursManagementTable = ({ departmentId }: { departmentId: number }) => {
  const [openCollapsibleId, setOpenCollapsibleId] = useState<string | undefined>(undefined)
  const [selectedWorkingHours, setSelectedWorkingHours] = useState<IWorkingHoursActiveTableData | undefined>(undefined)
  const [filter, setFilter] = useState<string | null>(null)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'ASC' })
  const { t } = useTranslation()
  const { me } = useMe()

  const { data: datesData, isLoading: isLoadingDatesData } = useGetClientWorkingHoursExistingDates({ departmentId })
  const { data: employeeNumber, isLoading: isLoadingEmployeeNumber } = useGetNumberOfEmployeesInDepartment({
    departmentId,
    year: filter ? Number(filter) : undefined,
  })
  const { data, isLoading: isLoadingArchiveHoursManagement } = useGetArchivedWorkingHoursManagement({
    departmentId,
    year: filter ? Number(filter) : undefined,
  })

  const availableYears = [...new Set((datesData || [])?.map(date => dayjs(date).year()))].sort((a, b) => b - a)
  const segmentOptions = useMemo(
    () =>
      availableYears.map(y => ({
        label: y.toString(),
        value: y.toString(),
      })),
    [availableYears],
  )

  useEffect(() => {
    if (!filter && segmentOptions.length > 0) {
      setFilter(segmentOptions[0].label)
    }
  }, [filter, segmentOptions])

  if (!filter) {
    return null
  }

  const filteredData = (data || []).filter(d => (filter ? d.date.includes(filter) : true))

  const dataByMonth = [...new Set(filteredData.map(fd => fd.date))]
    .sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? -1 : 1))
    .map(date => ({
      date,
      data: filteredData.filter(d => d.date === date),
      numOfSent: filteredData.filter(d => d.date === date).filter(d => !!d.date_sent).length,
    }))

  return (
    <ComponentWrapper>
      {(segmentOptions || []).length > 1 && (
        <SegmentedTabs
          containerProps={{ className: 'mb-6' }}
          options={segmentOptions}
          value={filter}
          onChange={(val: any) => {
            setFilter(val === filter ? null : val)
          }}
        />
      )}
      {dataByMonth.map(monthData => {
        const hasNotes = (monthData?.data || [])?.some(td => !!td.note)

        return (
          <Collapse
            key={monthData.date}
            hasBorder={!(openCollapsibleId === monthData.date)}
            isOpen={monthData.date === openCollapsibleId}
            onClick={() => setOpenCollapsibleId(prevId => (prevId === monthData.date ? undefined : monthData.date))}
            Title={() => (
              <CollapsibleTitle
                title={dayjs(monthData.date).format('MMMM')}
                rightContent={
                  <div className='text-right'>
                    <span className='text-base font-normal leading-[17.60px]  text-gray-600'>Poslano </span>
                    <span className='text-base font-medium leading-[17.60px] text-neutral-900'>
                      {monthData?.numOfSent || 0}/{employeeNumber?.[dayjs(monthData.date).format('YYYY-MM-DD')] || 0}
                    </span>
                  </div>
                }
              />
            )}
          >
            <Table
              order={order}
              isLoading={isLoadingArchiveHoursManagement || isLoadingDatesData || isLoadingEmployeeNumber}
              columns={[
                {
                  key: 'name',
                  dataIndex: 'name',
                  title: t('working_hours_management.name'),
                  order: setOrder,
                  render: tableData => (
                    <div className='flex items-center gap-4'>
                      {tableData['name']}
                      {tableData['hours_type'] === UpdateEmployeeDtoHoursTypeEnum.STUDENT && (
                        <div className='rounded-[42px] bg-[#F3F3F3] px-[11px] py-[4px] text-[14px] font-normal text-gray-500'>
                          Student
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  key: 'overtime_hours',
                  dataIndex: 'overtime_hours',
                  title: t('working_hours_management.overtime'),
                  decoration: tw`w-[150px]`,
                  order: setOrder,
                  hidden: !me?.companySettings?.hasOvertime,
                  render: tableData => <>{tableData['overtime_hours'] || 0}</>,
                },
                {
                  key: 'note',
                  dataIndex: 'note',
                  title: t('working_hours_management.note'),
                  decoration: tw`w-[150px]`,
                  order: setOrder,
                  render: tableData => {
                    const hasNote = !!tableData['note']
                    return (
                      <>
                        {hasNote ? (
                          <div data-tooltip-id='notes-tooltip' data-tooltip-content={tableData['note']}>
                            <img src={Assets.NoteIcon} alt={'note-icon'} />
                          </div>
                        ) : (
                          '-'
                        )}
                      </>
                    )
                  },
                  hidden: !hasNotes,
                },
                {
                  key: 'date_sent',
                  dataIndex: 'date_sent',
                  title: t('working_hours_management.sent'),
                  decoration: tw`w-[150px]`,
                  order: setOrder,
                  render: tableData => (
                    <>{tableData['date_sent'] ? dayjs(tableData['date_sent']).format('DD.MM.YYYY') : '-'}</>
                  ),
                },
                {
                  key: 'date_approved',
                  dataIndex: 'date_approved',
                  decoration: tw`w-[150px]`,
                  title: t('working_hours_management.approved_at'),
                  order: setOrder,
                  render: tableData => (
                    <>{tableData['date_approved'] ? dayjs(tableData['date_approved']).format('DD.MM.YYYY') : '-'}</>
                  ),
                },
              ]}
              onRowClick={(id, row) => setSelectedWorkingHours(row)}
              data={(monthData?.data || [])?.map(d => ({ ...d, key: d.id })).sort(orderData)}
            />
          </Collapse>
        )
      })}
      {!!selectedWorkingHours && (
        <HoursManagementContentModal
          isOpen={!!selectedWorkingHours}
          isArchive
          onClose={() => setSelectedWorkingHours(undefined)}
          date={dayjs(selectedWorkingHours.date).format('YYYY-MM-15')}
          departmentId={departmentId}
          employeeId={selectedWorkingHours.employee_id}
          isStudent={selectedWorkingHours.hours_type === UpdateEmployeeDtoHoursTypeEnum.STUDENT}
        />
      )}
    </ComponentWrapper>
  )
}
