import { axiosApiInstance } from '@api/axiosInstance'
import { useMutation } from '@tanstack/react-query'

export const useEmployeeTemplate = () => {
  const { employee } = axiosApiInstance

  const checkImportTemplate = useMutation({
    mutationFn: employee.employeeControllerCheckImportTemplate,
  })

  const importTemplate = useMutation({
    mutationFn: employee.employeeControllerImportTemplate,
  })

  const exportTemplate = useMutation({
    mutationFn: employee.employeeControllerExportUsers,
  })

  return {
    exportTemplate,
    checkImportTemplate,
    importTemplate,
  }
}
