import dayjs from 'dayjs'
import 'dayjs/locale/hr.js'
import updateLocale from 'dayjs/plugin/updateLocale' // import plugin

dayjs.extend(updateLocale)
dayjs.locale('hr')
dayjs.updateLocale('hr', {
  months: [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac',
  ],
})
